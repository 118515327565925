/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Stack, Banner, BodyText, ButtonToggle, Card, Form} from '@servicetitan/design-system';
function _createMdxContent(props) {
  const _components = Object.assign({
    pre: "pre",
    code: "code",
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    h3: "h3",
    hr: "hr",
    h2: "h2",
    span: "span",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  if (!Form) _missingMdxReference("Form", false);
  if (!Form.ButtonToggle) _missingMdxReference("Form.ButtonToggle", true);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<ButtonToggle\n\tonChange={(value) => console.log(\"onChange\", value)}\n\toptions={[\n\t\t{\n\t\t\ttext: \"Ongoing\",\n\t\t\tvalue: \"Ongoing\",\n\t\t\tselected: true,\n\t\t},\n\t\t{\n\t\t\ttext: \"One-Time\",\n\t\t\tvalue: \"One-Time\",\n\t\t},\n\t\t{\n\t\t\ttext: \"Never\",\n\t\t\tvalue: \"Never\",\n\t\t},\n\t]}\n/>\n")), "\n", React.createElement(_components.h1, {
    id: "small--large-variation",
    style: {
      position: "relative"
    }
  }, "Small & Large Variation", React.createElement(_components.a, {
    href: "#small--large-variation",
    "aria-label": "small  large variation permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Just like our ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/button/"
  }, "Buttons")), ", we can change size of our Button Toggle."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={2}>\n\t{([state, setState]) => (\n\t\t<ButtonToggle\n\t\t\tvalue={state}\n\t\t\tsmall\n\t\t\tonChange={(value) => setState(value)}\n\t\t\toptions={[\n\t\t\t\t{\n\t\t\t\t\ttext: \"1\",\n\t\t\t\t\tvalue: 1,\n\t\t\t\t},\n\t\t\t\t{\n\t\t\t\t\ttext: \"2\",\n\t\t\t\t\tvalue: 2,\n\t\t\t\t},\n\t\t\t]}\n\t\t/>\n\t)}\n</State>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={2}>\n\t{([state, setState]) => (\n\t\t<ButtonToggle\n\t\t\tvalue={state}\n\t\t\tonChange={(value) => setState(value)}\n\t\t\toptions={[\n\t\t\t\t{\n\t\t\t\t\ttext: \"1\",\n\t\t\t\t\tvalue: 1,\n\t\t\t\t},\n\t\t\t\t{\n\t\t\t\t\ttext: \"2\",\n\t\t\t\t\tvalue: 2,\n\t\t\t\t},\n\t\t\t]}\n\t\t/>\n\t)}\n</State>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={2}>\n\t{([state, setState]) => (\n\t\t<ButtonToggle\n\t\t\tvalue={state}\n\t\t\tlarge\n\t\t\tonChange={(value) => setState(value)}\n\t\t\toptions={[\n\t\t\t\t{\n\t\t\t\t\ttext: \"1\",\n\t\t\t\t\tvalue: 1,\n\t\t\t\t},\n\t\t\t\t{\n\t\t\t\t\ttext: \"2\",\n\t\t\t\t\tvalue: 2,\n\t\t\t\t},\n\t\t\t]}\n\t\t/>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "color-options",
    style: {
      position: "relative"
    }
  }, "Color Options", React.createElement(_components.a, {
    href: "#color-options",
    "aria-label": "color options permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "We have three states of ", React.createElement(_components.strong, null, "Button Toggles"), " just like ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/button/"
  }, "Buttons")), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<ButtonToggle\n\tonChange={(value) => console.log(\"onChange\", value)}\n\toptions={[\n\t\t{\n\t\t\ttext: \"On\",\n\t\t\tvalue: \"on\",\n\t\t\tselected: true,\n\t\t},\n\t\t{\n\t\t\ttext: \"Off\",\n\t\t\tvalue: \"off\",\n\t\t},\n\t]}\n/>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<ButtonToggle\n\tcolor=\"neutral\"\n\tonChange={(value) => console.log(\"onChange\", value)}\n\toptions={[\n\t\t{\n\t\t\ttext: \"On\",\n\t\t\tvalue: \"on\",\n\t\t\tselected: true,\n\t\t},\n\t\t{\n\t\t\ttext: \"Off\",\n\t\t\tvalue: \"off\",\n\t\t},\n\t]}\n/>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<ButtonToggle\n\tcolor=\"negative\"\n\tonChange={(value) => console.log(\"onChange\", value)}\n\toptions={[\n\t\t{\n\t\t\ttext: \"On\",\n\t\t\tvalue: \"on\",\n\t\t\tselected: true,\n\t\t},\n\t\t{\n\t\t\ttext: \"Off\",\n\t\t\tvalue: \"off\",\n\t\t},\n\t]}\n/>\n")), "\n", React.createElement(_components.h1, {
    id: "disabled-state",
    style: {
      position: "relative"
    }
  }, "Disabled State", React.createElement(_components.a, {
    href: "#disabled-state",
    "aria-label": "disabled state permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The disabled state should not show any option selected."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<ButtonToggle\n\tdisabled\n\tonChange={(value) => console.log(\"onChange\", value)}\n\toptions={[\n\t\t{\n\t\t\ttext: \"Active\",\n\t\t\tvalue: \"active\",\n\t\t\tselected: true,\n\t\t},\n\t\t{\n\t\t\ttext: \"Inactive\",\n\t\t\tvalue: \"inactive\",\n\t\t},\n\t]}\n/>\n")), "\n", React.createElement(_components.h3, {
    id: "disabling-individual-options",
    style: {
      position: "relative"
    }
  }, "Disabling individual options", React.createElement(_components.a, {
    href: "#disabling-individual-options",
    "aria-label": "disabling individual options permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<ButtonToggle\n\tonChange={(value) => console.log(\"onChange\", value)}\n\toptions={[\n\t\t{\n\t\t\ttext: \"Ongoing\",\n\t\t\tvalue: \"Ongoing\",\n\t\t},\n\t\t{\n\t\t\ttext: \"One-Time\",\n\t\t\tvalue: \"One-Time\",\n\t\t\tdisabled: true,\n\t\t},\n\t\t{\n\t\t\ttext: \"Never\",\n\t\t\tvalue: \"Never\",\n\t\t\tdisabled: true,\n\t\t\tselected: true,\n\t\t},\n\t]}\n/>\n")), "\n", React.createElement(_components.h1, {
    id: "icons",
    style: {
      position: "relative"
    }
  }, "Icons", React.createElement(_components.a, {
    href: "#icons",
    "aria-label": "icons permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Button Toggles support the same icon options found in ", React.createElement(_components.a, {
    href: "/components/button/#buttons-with-icon"
  }, "Buttons"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack spacing={5} wrap=\"wrap\">\n\t<State initial={1}>\n\t\t{([state, setState]) => (\n\t\t\t<>\n\t\t\t\t<ButtonToggle\n\t\t\t\t\tclassName=\"d-if\"\n\t\t\t\t\tvalue={state}\n\t\t\t\t\tonChange={(value) => setState(value)}\n\t\t\t\t\toptions={[\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\ttext: \"Top\",\n\t\t\t\t\t\t\ticonName: \"arrow_upward\",\n\t\t\t\t\t\t\tvalue: 1,\n\t\t\t\t\t\t},\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\ttext: \"Bottom\",\n\t\t\t\t\t\t\ticonName: \"arrow_downward\",\n\t\t\t\t\t\t\tvalue: 2,\n\t\t\t\t\t\t},\n\t\t\t\t\t]}\n\t\t\t\t/>\n\t\t\t</>\n\t\t)}\n\t</State>\n\t<State initial={2}>\n\t\t{([state, setState]) => (\n\t\t\t<ButtonToggle\n\t\t\t\tclassName=\"d-if\"\n\t\t\t\tvalue={state}\n\t\t\t\tonChange={(value) => setState(value)}\n\t\t\t\toptions={[\n\t\t\t\t\t{\n\t\t\t\t\t\ticonName: \"view_week\",\n\t\t\t\t\t\tvalue: 1,\n\t\t\t\t\t},\n\t\t\t\t\t{\n\t\t\t\t\t\ticonName: \"view_module\",\n\t\t\t\t\t\tvalue: 2,\n\t\t\t\t\t},\n\t\t\t\t]}\n\t\t\t/>\n\t\t)}\n\t</State>\n</Stack>\n")), "\n", React.createElement(_components.h1, {
    id: "clearable",
    style: {
      position: "relative"
    }
  }, "Clearable", React.createElement(_components.a, {
    href: "#clearable",
    "aria-label": "clearable permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={2}>\n\t{([state, setState]) => (\n\t\t<ButtonToggle\n\t\t\tclearable\n\t\t\tvalue={state}\n\t\t\tonChange={(value) => {\n\t\t\t\tsetState(value)\n\t\t\t\tconsole.log(\"onChange\", value)\n\t\t\t}}\n\t\t\toptions={[\n\t\t\t\t{\n\t\t\t\t\ttext: \"1\",\n\t\t\t\t\tvalue: 1,\n\t\t\t\t},\n\t\t\t\t{\n\t\t\t\t\ttext: \"2\",\n\t\t\t\t\tvalue: 2,\n\t\t\t\t},\n\t\t\t]}\n\t\t/>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "controlled",
    style: {
      position: "relative"
    }
  }, "Controlled", React.createElement(_components.a, {
    href: "#controlled",
    "aria-label": "controlled permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst ExampleLarge = () => {\n\tconst [state, setState] = React.useState(0)\n\tconst interval = React.useRef()\n\n\tReact.useEffect(() => {\n\t\tinterval.current = setInterval(() => {\n\t\t\tsetState((state) => (state === 1 ? 0 : 1))\n\t\t}, 1000)\n\t\t;() => clearInterval(interval.current)\n\t}, [])\n\n\treturn (\n\t\t<ButtonToggle\n\t\t\tvalue={state}\n\t\t\tonChange={(value) => {\n\t\t\t\tclearTimeout(interval.current)\n\t\t\t\tsetState(value)\n\t\t\t}}\n\t\t\toptions={[\n\t\t\t\t{\n\t\t\t\t\ttext: \"0\",\n\t\t\t\t\tvalue: 0,\n\t\t\t\t},\n\t\t\t\t{\n\t\t\t\t\ttext: \"1\",\n\t\t\t\t\tvalue: 1,\n\t\t\t\t},\n\t\t\t]}\n\t\t/>\n\t)\n}\nrender (ExampleLarge)\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "content-guidelines",
    style: {
      position: "relative"
    }
  }, "Content Guidelines", React.createElement(_components.a, {
    href: "#content-guidelines",
    "aria-label": "content guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "give-a-descriptive-action-oriented-label",
    style: {
      position: "relative"
    }
  }, "Give a descriptive, action-oriented label", React.createElement(_components.a, {
    href: "#give-a-descriptive-action-oriented-label",
    "aria-label": "give a descriptive action oriented label permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The user relies on the button toggle label to provide context and identify how the options are related and what the choices control. Include a verb to prompt the user to take action."), "\n", React.createElement(_components.p, null, "Labels should follow the content guidelines for ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/patterns/forms/#labels"
  }, "labels")), "."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Form.ButtonToggle, {
    label: "Set Job Associations",
    onChange: value => console.log("onChange", value),
    options: [{
      text: "Allow",
      value: "allow",
      selected: true
    }, {
      text: "Require",
      value: "require"
    }, {
      text: React.createElement(_components.span, {
        style: {
          whiteSpace: "nowrap"
        }
      }, "Do Not Allow"),
      value: "notallowed"
    }]
  })), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Form.ButtonToggle, {
    label: "Associations",
    onChange: value => console.log("onChange", value),
    options: [{
      text: "Allow",
      value: "allow",
      selected: true
    }, {
      text: "Require",
      value: "require"
    }, {
      text: React.createElement(_components.span, {
        style: {
          whiteSpace: "nowrap"
        }
      }, "Do Not Allow"),
      value: "notallowed"
    }]
  })), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "button-name-should-describe-the-option",
    style: {
      position: "relative"
    }
  }, "Button name should describe the option", React.createElement(_components.a, {
    href: "#button-name-should-describe-the-option",
    "aria-label": "button name should describe the option permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The user should be able to use the button name to predict what the option will do."), "\n", React.createElement(_components.p, null, "Each button should be given 1 to 3 word names that communicate their effect and are easy to understand. Users should have no doubt as to what they are choosing."), "\n", React.createElement(_components.p, null, "List buttons in a logical order to make it easier to understand the options."), "\n", React.createElement(_components.p, null, "Button toggles should follow the content guidelines for ", React.createElement(_components.a, {
    href: "/components/button#content-guidelines"
  }, "buttons"), "."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 3
  }, React.createElement(ButtonToggle, {
    onChange: value => console.log("onChange", value),
    options: [{
      text: "Allow",
      value: "allow",
      selected: true
    }, {
      text: "Require",
      value: "require"
    }, {
      text: React.createElement(_components.span, {
        style: {
          whiteSpace: "nowrap"
        }
      }, "Do Not Allow"),
      value: "notallowed"
    }]
  }), React.createElement(ButtonToggle, {
    onChange: value => console.log("onChange", value),
    options: [{
      text: "On",
      value: "on",
      selected: true
    }, {
      text: "Off",
      value: "off"
    }, {
      text: "Hide Option",
      value: "hide"
    }]
  }))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 3
  }, React.createElement(ButtonToggle, {
    onChange: value => console.log("onChange", value),
    options: [{
      text: "Require",
      value: "require",
      selected: true
    }, {
      text: "Allow",
      value: "allow"
    }, {
      text: React.createElement(_components.span, {
        style: {
          whiteSpace: "nowrap"
        }
      }, "Do Not Allow"),
      value: "notallowed"
    }]
  }), React.createElement(ButtonToggle, {
    onChange: value => console.log("onChange", value),
    options: [{
      text: "Off",
      value: "off",
      selected: true
    }, {
      text: React.createElement(_components.span, {
        style: {
          whiteSpace: "nowrap"
        }
      }, "Hide Option From The User"),
      value: "hide"
    }, {
      text: "On",
      value: "on"
    }]
  }))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A ", React.createElement(_components.strong, null, "Button Toggle"), " should:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Group together actions that are related"), "\n", React.createElement(_components.li, null, "Have between 2 and 5 actions to choose between"), "\n", React.createElement(_components.li, null, "Limit action names to one or two short words"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-components",
    style: {
      position: "relative"
    }
  }, "Related Components", React.createElement(_components.a, {
    href: "#related-components",
    "aria-label": "related components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "For an individual button, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/button/"
  }, "Button")), "."), "\n", React.createElement(_components.li, null, "For a group of buttons that don't need the ability to toggle, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/button-group/"
  }, "Button Group")), "."), "\n", React.createElement(_components.li, null, "For longer labels or more items, use a list of ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/radio/"
  }, "Radios")), "."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "We recommend using the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/form/"
  }, "Form")), " shorthand component ", React.createElement(_components.code, null, "<Form.ButtonToggle />"), ". It automatically provide the correct Form grouping structure and spacing. You can import the standalone component for custom Form layouts."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { ButtonToggle } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
